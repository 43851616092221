<template>
  <v-card class="max-h-content-container app-calendar position-relative overflow-hidden text-sm">
    <div class="h-full">
      <div v-if="refCalendar" class="calendar-header mx-6 my-5 d-flex align-center flex-wrap">
        <v-btn icon class="d-inline-block d-md-none me-1">
          <v-icon size="30">
            {{ icons.mdiMenu }}
          </v-icon>
        </v-btn>
        <v-btn icon class="me-1" @click="refCalendar.prev()">
          <v-icon size="30">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <v-btn icon class="me-3" @click="refCalendar.next()">
          <v-icon size="30">
            {{ icons.mdiChevronRight }}
          </v-icon>
        </v-btn>
        <p class="font-weight-semibold text-xl text--primary mb-0 me-6">
          {{ refCalendar.title }}
        </p>

        <v-spacer></v-spacer>

        <v-btn-toggle v-model="activeCalendarView" color="primary" class="mt-3 mt-sm-0">
          <v-btn
            v-for="calendarViewOption in calendarViewOptions"
            :key="calendarViewOption.value"
            elevation="0"
            :value="calendarViewOption.value"
          >
            {{ calendarViewOption.text }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <v-calendar
        ref="refCalendar"
        v-model="calendarValue"
        :type="activeCalendarView"
        :events="calendarEvents"
        :event-margin-bottom="5"
        locale="es"
        :event-overlap-mode="'column'"
        :event-overlap-threshold="10"
        :event-ripple="true"
      ></v-calendar>
    </div>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onUnmounted, computed, watch, inject } from 'vue'
import { mdiChevronLeft, mdiChevronRight, mdiMenu } from '@mdi/js'
import store from '@/store'
import { hexToRgb, getVuetify } from '@core/utils'
import calendarStoreModule from './calendarStoreModule'

export default {
  components: {},
  setup() {
    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const appCalendar = 'app-calendar'

    // Register module
    if (!store.hasModule(appCalendar)) {
      store.registerModule(appCalendar, calendarStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appCalendar)) store.unregisterModule(appCalendar)
    })

    // ————————————————————————————————————
    //* ——— Vuetify Instance
    // ————————————————————————————————————

    const $vuetify = getVuetify()

    const date = inject('date')

    // ————————————————————————————————————
    //* ——— Templare Ref
    // ————————————————————————————————————

    const refCalendar = ref(null)

    // ————————————————————————————————————
    //* ——— Calendar View/Type
    // ————————————————————————————————————

    const activeCalendarView = ref('month')
    const calendarViewOptions = [
      { text: 'Mes', value: 'month' },
      { text: 'Semana', value: 'week' },
      { text: 'Día', value: 'day' },
    ]

    // ————————————————————————————————————
    //* ——— Calendar Value & Events
    // ————————————————————————————————————

    const calendarValue = ref()
    const calendarEvents = ref([])

    const blankEvent = {
      id: '',
      name: '',
      start: '',
      end: '',
      timed: false,
      extendedProps: {
        calendar: '',
        guests: [],
        location: '',
        description: '',
      },
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))

    const fetchGroups = () => {
      store
        .dispatch('app-calendar/fetchGroups', {})
        .then(response => {
          const { filteredData } = response.data
          const events = []

          filteredData.forEach(group => {
            const bg = generarColor(group.name)
            const bgtext = generarColorContraste(bg)
            group.subjects.forEach(subject => {
              subject.classDays.forEach(classDay => {
                let event = {}

                event.name = `${subject.name} - ${group.name}`
                event.start = new Date(classDay.start)
                event.end = new Date(classDay.end)
                event.timed = true
                const rgbColor = hexToRgb(bg)

                event.color = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b})`
                event.eventTextColor = bgtext

                events.push(event)
              })
            })
          })

          calendarEvents.value = events
        })
        .catch(error => {
          console.log(error)
        })
    }

    const generarColor = string => {
      // Primero, calculamos un hash a partir del string
      let hash = 0
      for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
      }

      // Convertimos el hash a un color hexadecimal
      let color = '#'
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff
        color += value.toString(16).padStart(2, '0')
      }

      return color
    }

    const generarColorContraste = color => {
      // Convertimos el color hexadecimal a RGB
      let r = parseInt(color.substring(1, 3), 16)
      let g = parseInt(color.substring(3, 5), 16)
      let b = parseInt(color.substring(5, 7), 16)

      // Calculamos el nivel de luminosidad del color utilizando la fórmula YIQ
      let luminosidad = (r * 299 + g * 587 + b * 114) / 1000

      // Devolvemos un color negro o blanco según el nivel de luminosidad
      if (luminosidad > 128) {
        return '#000000' // negro
      } else {
        return '#FFFFFF' // blanco
      }
    }

    fetchGroups()
    return {
      // Template Refs
      refCalendar,

      // Calendar View/Type
      activeCalendarView,
      calendarViewOptions,

      // Calendar Value & Events
      calendarValue,
      calendarEvents,
      event,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiMenu,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/calendar.scss';
</style>
